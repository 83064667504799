import { AuthenticationService, AuthorizationService } from '@swpsoftware/commons-ui';
import Configuration from '@/services/Configuration';

class Authentication {
  private readonly authenticationService: AuthenticationService;

  private readonly authorizationService: AuthorizationService;

  constructor() {
    this.authenticationService = new AuthenticationService(Configuration.authenticationUrl());
    this.authorizationService = new AuthorizationService(Configuration.authorizationUrl());
  }

  public async login(userName: string, password: string): Promise<boolean> {
    const loginSuccessful = await this.authenticationService.login(userName, password);
    if (!loginSuccessful) {
      return false;
    }
    const mandators = await this.authorizationService.getMandators();
    if (mandators.length > 0 && mandators[0].id) {
      return this.authenticationService.loginForMandator(mandators[0].id);
    }
    return true;
  }

  public isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  public logOut(): void {
    this.authenticationService.logOut();
  }
}

export default Authentication;
