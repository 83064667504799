import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Configuration from '@/services/Configuration';
import { Configuration as ServiceConfiguration } from '@swpsoftware/commons-ui';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';

ServiceConfiguration.set({
  AuthenticationBaseUrl: Configuration.authenticationUrl(),
});

createApp(App)
  .use(router)
  .use(createPinia())
  .mount('#app');
