<template>
  <router-view/>
</template>

<style>
#app > * {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 12px;
}

</style>
