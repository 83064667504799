import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Authentication from '@/services/Authentication';
import LoginView from '../views/LoginView.vue';
import ResultView from '../views/ResultView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/',
    name: 'result',
    component: ResultView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  if (!new Authentication().isAuthenticated() && to.name !== 'login') {
    return { name: 'login' };
  }
  return true;
});

export default router;
