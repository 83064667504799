import { FlatLicenseItem } from '@/viewmodels/FlatLicenseItem';
import { FilterCriteria } from '@/viewmodels/FilterCriteria';

const matchesFilter = (item: FlatLicenseItem, filter: FilterCriteria)
  : boolean => {
  const { term } = filter;
  return ((!filter.isDeprecated || item.isDeprecatedType)
      && (!filter.isFsf || item.isFsf)
      && (!filter.isOsi || item.isOsi)
      && (item.name.includes(term)
      || (item.displayName.includes(term))
      || item.projectName.includes(term)
      || item.expression.includes(term)));
};

export default matchesFilter;
