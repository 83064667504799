import CommitDto from '@/contracts/CommitDto';
import TestType from '@/contracts/TestType';
import Configuration from '@/services/Configuration';
import { HttpService } from '@swpsoftware/commons-ui';

export default class FilterService {
  private readonly baseUrl = `${Configuration.apiUrl()}api/v1.0`;

  private readonly httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  private async sendGetRequest<T>(url: string, urlParams: URLSearchParams | null = null)
    : Promise<T> {
    const response = await this.httpService.get(url, urlParams);
    return response.getData() as T;
  }

  public getTestTypes = async (commitHash: string): Promise<TestType[]> => this
    .sendGetRequest<TestType[]>(`${this.baseUrl}/Report/${commitHash}/kind`);

  public getCommits = async (projectName: string, branchName: string): Promise<CommitDto[]> => this
    .sendGetRequest<CommitDto[]>(`${this.baseUrl}/Project/${projectName}/branch/${branchName}/commit`);

  public getBranches = async (projectName: string): Promise<string[]> => this
    .sendGetRequest<string[]>(`${this.baseUrl}/Project/${projectName}/branch`);

  public getProjects = async (): Promise<string[]> => this
    .sendGetRequest<string[]>(`${this.baseUrl}/Project`);

  public getResultUrl = async (commitHash: string, type: TestType): Promise<string> => this
    .sendGetRequest(`${this.baseUrl}/Report`, new URLSearchParams(`hash=${commitHash}&type=${type}`));
}
