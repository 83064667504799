class Configuration {
  public static authenticationUrl() {
    return new URL(process.env.VUE_APP_Authentication_Url);
  }

  public static authorizationUrl() {
    return new URL(process.env.VUE_APP_Authorization_Url);
  }

  public static apiUrl() {
    return new URL(process.env.VUE_APP_Api_Url);
  }
}

export default Configuration;
