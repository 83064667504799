import { FlatLicenseItem } from '@/viewmodels/FlatLicenseItem';
import {
  CollapsibleLicenseData,
  CollapsibleProjectLicense,
  CollapsibleProjectLicenseCollection,
} from '@/contracts/ProjectLicenseCollection';

export const transform = (flatItems: FlatLicenseItem[]): CollapsibleProjectLicenseCollection => {
  const tree: CollapsibleProjectLicenseCollection = { projects: [] };
  let lastProject: CollapsibleProjectLicense;
  let lastLicense: CollapsibleLicenseData;
  flatItems.forEach((flatItem) => {
    const isNewLicenseNode = flatItem.expression !== lastLicense?.expression
      || flatItem.projectName !== lastProject?.projectName;
    if (flatItem.projectName !== lastProject?.projectName) {
      const project: CollapsibleProjectLicense = {
        projectName: flatItem.projectName,
        isCollapsed: true,
        licenses: [],
      };
      tree.projects.push(project);
      lastProject = project;
    }
    if (isNewLicenseNode) {
      const license: CollapsibleLicenseData = {
        count: flatItem.count,
        expression: flatItem.expression,
        isOsi: flatItem.isOsi,
        isFsf: flatItem.isFsf,
        isDeprecatedType: flatItem.isDeprecatedType,
        isCollapsed: true,
        packages: [],
      };
      lastProject.licenses.push(license);
      lastLicense = license;
    }
    lastLicense.packages.push({
      name: flatItem.name,
      displayName: flatItem.displayName,
      url: flatItem.url,
      version: flatItem.version,
    });
  });
  return tree;
};
