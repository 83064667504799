import { FlatLicenseItem } from '@/viewmodels/FlatLicenseItem';
import { ProjectLicense } from '@/contracts/ProjectLicenseCollection';

export const flatLicenseTree = (projectLicenses: ProjectLicense[]): FlatLicenseItem[] => {
  const items: FlatLicenseItem[] = [];
  projectLicenses.forEach((projectLicense) => {
    projectLicense.licenses.forEach((license) => {
      license.packages.forEach((packageData) => {
        items.push({
          projectName: projectLicense.projectName,
          count: license.count,
          expression: license.expression,
          isDeprecatedType: license.isDeprecatedType,
          isFsf: license.isFsf,
          isOsi: license.isOsi,
          name: packageData.name,
          displayName: packageData.displayName,
          url: packageData.url,
          version: packageData.version,
        });
      });
    });
  });
  return items;
};
